import React from "react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const FrontPage = () => (
  <Layout>
    <PageHeader>
      About!
    </PageHeader>
    <div id="content">
      What's it all about :thinking:
    </div>
  </Layout>
);

export default FrontPage;